import { GenericObject } from '../../utils/GenericObject';
import { userReducer } from './user/user-reducer';
import { AnyAction } from '../AnyAction';
import { User } from '../../domain/User/User';

export interface CurrentUser {
  user?: User;
  isPending: boolean;
  error?: GenericObject;
}

export interface GlobalState {
  currentUser: CurrentUser;
}

export const initialState: GlobalState = {
  currentUser: {
    isPending: false,
  },
};

export const globalReducer = (
  state: GlobalState,
  action: AnyAction
): GlobalState => {
  const { currentUser } = state;

  return {
    currentUser: userReducer(currentUser, action),
  };
};
