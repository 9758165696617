import React, { useContext, useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { UserContext } from '../../app/authenticated/Authenticated';
import { makeStyles } from '@rentacenter/racstrap';
import { appConfig } from '../../config/app-config';
import { MicroFrontend } from './MicroFrontEnd';
import { ContainerContext } from '../../app/authenticated/Authenticated';
export const dashboardTestId = 'dashboardTestId';
const useStyles = makeStyles(() => ({
  microAppContainer: {
    marginTop: '4rem',
  },
}));

export const Dashboard = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const { activeStore }: any = useContext(UserContext);
  const containerData = useContext(ContainerContext);
  const [storeNumber, setStoreNumber] = useState('');

  useEffect(() => {
    setStoreNumber(activeStore);
    // eslint-disable-next-line no-console
    console.log(appConfig.microfrontends.dashboard, history);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStore]);

  return (
    <div className={classes.microAppContainer} data-testid={dashboardTestId}>
      <MicroFrontend
        config={appConfig.microfrontends.dashboard}
        history={history}
        store={storeNumber}
        customProps={containerData}
      />
    </div>
  );
};
