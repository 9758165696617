import React, { useContext, useEffect, useState } from 'react';
import {
  Redirect,
  Route,
  RouteComponentProps,
  Switch,
  withRouter,
} from 'react-router-dom';
import { RouteName, RoutePath } from '../../../config/route-config';
import { UserContext } from '../Authenticated';
// import { NotFound } from '../../../components/not-found/NotFound';
// import { GlobalDeps } from '../../../GlobalDeps';
// import { SessionStorageKeys } from '../../../config/storage-config';
import { AllowedRoute } from './getAllowedRoutes';
import { TestComponent } from '../../../components/Test/TestComponent';
// import { RoutePath } from '../../../config/route-config';
// import { Header } from '../../../components/Header/HeaderComponent';

interface Props extends RouteComponentProps {
  allowedRoutes: AllowedRoute[];
}

export const routesTestId = 'routesTestId';

export const Routes = withRouter((props: Props) => {
  const { activeStore }: any = useContext(UserContext);
  const { allowedRoutes } = props; //history

  const [shouldDisplayRoutes, displayRoutes] = useState<boolean>(true);

  useEffect((): void => {
    displayRoutes(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /* eslint-disable no-console */

  useEffect((): void => {
    console.log('alloweded routes: ', allowedRoutes);
  }, [allowedRoutes]);

  return (
    <div data-testid={routesTestId}>
      {shouldDisplayRoutes && (
        <Switch>
          {activeStore !== undefined ? (
            <Route exact path={RoutePath.Index}>
              <Redirect
                to={
                  allowedRoutes[0]?.name === RouteName.Payment &&
                  allowedRoutes.filter((e) => e.name == 'Payment').length == 3
                    ? '/payment1/paymentsearch'
                    : allowedRoutes[0]?.name === RouteName.Operations
                    ? '/pricing/upload'
                    : allowedRoutes[0]?.name === RouteName.Payment &&
                      allowedRoutes.filter((e) => e.name == 'Payment').length ==
                        1
                    ? '/payment'
                    : allowedRoutes[0]?.path
                }
              />
            </Route>
          ) : null}
          <Route key="/test" path="/test" component={TestComponent} />
          {/* <Route exact path={RoutePath.Index}>
            <Header />
          </Route> */}
          {allowedRoutes.map((allowedRoute: AllowedRoute) => (
            <Route
              key={allowedRoute.path}
              path={allowedRoute.path}
              component={allowedRoute.component}
            />
          ))}
          {/* <Route path={RoutePath.NotFound} component={NotFound} />
          <Route>
            <Redirect to={RoutePath.NotFound} />
          </Route> */}
        </Switch>
      )}
    </div>
  );
});
