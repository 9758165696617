import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { DailyActivityPlanner } from '../../../components/microfrontends/DailyActivityPlanner';
import { Payment1 } from '../../../components/microfrontends/payment1';
import { ManualPO } from '../../../components/microfrontends/ManualPO';
import { POReceiving } from '../../../components/microfrontends/POReceiving';
import { DOF } from '../../../components/microfrontends/DOF';
import { RouteName, RoutePath } from '../../../config/route-config';
import { NavMenuPermission } from '../../../domain/Permission/NavMenuPermission';
import { CashManagement } from '../../../components/microfrontends/CashManagement';
import {
  canAccessAgreement,
  // canAccessCustomer,
  canAccessDailyActivityPlanner,
  canAccessDOF,
  canAccessManualPO,
  canAccessPay,
  canAccessPay1,
  canAccessInventory,
  canAccessPOReceiving,
  canAccessDrop,
  // canAccessPricing,
} from '../../../domain/Permission/permissions';
import { Payment } from '../../../components/microfrontends/Payment';
// import { Customer } from '../../../components/microfrontends/Customer';
import { Agreement } from '../../../components/microfrontends/Agreement';
import { appConfig } from '../../../config/app-config';
import { Inventory } from '../../../components/microfrontends/Inventory';

export interface AllowedSubRoute {
  readonly name: RouteName;
  readonly path: string;
  readonly component: FC<RouteComponentProps>;
}

/*
Returns the allowed sub routes.
This is not meant to control what sub routes should we display,
but its scope is to create a Route (react-router-dom) entry, so we can access the micro frontends.
*/
export const getAllowedSubRoutes = (
  actions: NavMenuPermission[]
): AllowedSubRoute[] =>
  actions.reduce((configs: AllowedSubRoute[], action: NavMenuPermission) => {
    if (canAccessPay1(action)) {
      return [
        ...configs,
        {
          name: RouteName.Payment,
          path: RoutePath.Payment1,
          component: Payment1,
        },
      ];
    }
    if (canAccessPay(action)) {
      return [
        ...configs,
        {
          name: RouteName.Payment,
          path: RoutePath.Payment,
          component: Payment,
        },
      ];
    }

    if (canAccessAgreement(action)) {
      return [
        ...configs,
        {
          name: RouteName.Agreement,
          path: RoutePath.Agreement,
          component: Agreement,
        },
      ];
    }

    if (canAccessInventory(action)) {
      return [
        ...configs,
        {
          name: RouteName.Inventory,
          path: RoutePath.Inventory,
          component: Inventory,
        },
      ];
    }

    if (canAccessPOReceiving(action)) {
      return [
        ...configs,
        {
          name: RouteName.ReceivingPO,
          path: RoutePath.ReceivingPO,
          component: POReceiving,
        },
      ];
    }

    if (canAccessManualPO(action)) {
      if (appConfig.microfrontends.manualPO.url.includes('manual-po')) {
        return [
          ...configs,
          {
            name: RouteName.ManualPO,
            path: RoutePath.ManualPO,
            component: ManualPO,
          },
        ];
      }
      return [
        ...configs,
        {
          name: RouteName.ManualPO,
          path: RoutePath.ManualPORedesign,
          component: ManualPO,
        },
      ];
    }

    if (canAccessDailyActivityPlanner(action)) {
      return [
        ...configs,
        {
          name: RouteName.DailyActivityPlanner,
          path: RoutePath.DailyActivityPlanner,
          component: DailyActivityPlanner,
        },
      ];
    }
    if (canAccessDrop(action)) {
      return [
        ...configs,
        {
          name: RouteName.Drop,
          path: RoutePath.Drop,
          component: CashManagement,
        },
      ];
    }
    if (canAccessDOF(action)) {
      return [
        ...configs,
        {
          name: RouteName.Store,
          path: RoutePath.Store,
          component: DOF,
        },
      ];
    }

    return configs;
  }, []);
