/* eslint-disable no-console */ import React, {
  useEffect,
  useState,
} from 'react';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import { getEmbedInfo } from '../../api/user';
// import { useLocation } from 'react-router-dom';
import './test.css';
export const TestComponent = () => {
  const [embedInfo, setEmbedInfo] = useState<any>();
  const [embedParam, setEmbedParam] = useState<any>();
  const searchParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    const pageLoad = async () => {
      const embedInfo = await getEmbedInfo();
      console.log('embedInfo', embedInfo);
      const storeNumbers =
        searchParams.get('store') !== undefined &&
        searchParams.get('store') !== null
          ? searchParams.get('store')?.split(',')
          : ['05535', '05534'];
      const startDate = searchParams.get('start') || '2023-02-17';
      const endDate = searchParams.get('end') || '2023-02-18';
      const region = searchParams.get('region') || 'R0027';
      const market = searchParams.get('market') || 'M0132';
      let storeSubString = '';
      if (storeNumbers && storeNumbers?.length > 0) {
        for (let i = 0; i < storeNumbers.length; i++) {
          storeSubString += `&rp:StoreNumber=${storeNumbers[i]}`;
        }
      }
      console.log('Set Embed Info', {
        store: storeSubString,
        start: startDate,
        end: endDate,
        region: region,
        market: market,
      });
      setEmbedParam({
        store: storeSubString,
        start: startDate,
        end: endDate,
        region: region,
        market: market,
      });
      setEmbedInfo(embedInfo);
    };
    pageLoad();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div
      style={{
        marginTop: '50px',
      }}
    >
      {' '}
      {embedInfo ? (
        <div>
          {' '}
          <PowerBIEmbed
            embedConfig={{
              type: 'report', // Supported types: report, dashboard, tile, visual and qna              id: '524a1940-b34d-41e5-80b8-65d251063ec4',
              embedUrl:
                embedInfo.embedUrl[0].embedUrl +
                `${embedParam.store}&rp:StartDate=${embedParam.start}&rp:EndDate=${embedParam.end}&rp:REGION=${embedParam.region}&rp:MARKET=${embedParam.market}`,
              accessToken: embedInfo.accessToken,
              tokenType: models.TokenType.Embed,
              settings: {
                commands: {
                  parameterPanel: {
                    enabled: false,
                    expanded: false,
                  },
                },
              },
            }}
            eventHandlers={
              new Map([
                [
                  'loaded',
                  function () {
                    console.log('Report loaded');
                  },
                ],
                [
                  'rendered',
                  function () {
                    console.log('Report rendered');
                  },
                ],
                [
                  'error',
                  function (event: any) {
                    console.log(event?.detail);
                  },
                ],
              ])
            }
            cssClassName={'report-style-class'}
            getEmbeddedComponent={(embeddedReport: any) => {
              window.report = embeddedReport;
            }}
          />{' '}
        </div>
      ) : null}
    </div>
  );
};
