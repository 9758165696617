/* eslint-disable no-console */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable sonarjs/cognitive-complexity */
import React, { useEffect, useState, useContext } from 'react';
import {
  Grid,
  MenuList,
  MenuItem,
  List,
  ListItem,
  Typography,
  RACTextbox,
  RACDatePicker,
  RACSearchSelect,
  RACButton,
} from '@rentacenter/racstrap';
import userImage from '../../assets/images/standard icon.png';
import Helper from '../../assets/images/filled-help.png';
import Phone from '../../assets/images/phonegrey.png';
import NewFeature from '../../assets/images/newfeature.png';
import Settings from '../../assets/images/settings.png';
import JobAid from '../../assets/images/jobaid.png';
import { ReactComponent as RACLogo } from '../../assets/images/RACUpdatedLogoNew.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/close_icon_2.svg';
import 'react-phone-number-input/style.css';
import moment from 'moment';
// import searchImageActive from '../../assets/images/search-active-icon.svg';
// import searchImageDefault from '../../assets/images/Search-icon.svg';
// import GlobalSearchBtn from '../../assets/images/search-btn.svg';
import { NavLink, useHistory } from 'react-router-dom';
import OutsideClickHandler from 'react-outside-click-handler';
import { Link } from 'react-router-dom';
import { configCall } from '../../api/user';
import { useAuthContext } from '../../context/auth/auth-context';
import clsx from 'clsx';
import { headerStyles } from '../../headerStyles';
import { UserContext } from '../../app/authenticated/Authenticated';
import { appConfig } from '../../config/app-config';
import { RouteName } from '../../config/route-config';
import { AllowedSubRoute } from '../../app/authenticated/routes/getAllowedSubRoutes';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// import TextField from '@mui/material/TextField';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

const manualpo = appConfig.microfrontends.manualPO.url.includes('manual-po')
  ? 'manual-po'
  : 'manualpo';

const AccountManagement = 'Account Management';

const manualPOSubMenu = {
  menu: 'Inventory',
  submenu: 'Manual PO',
  categories: 'Inventory',
  path: appConfig.microfrontends.manualPO.url.includes('manual-po')
    ? '/manual-po'
    : '/manualpo',
};

const InventoryPoSubmenu = [
  {
    menu: 'Inventory',
    submenu: 'Search Inventory',
    categories: 'Inventory',
    path: '/inventory/inventorysearch',
  },
  {
    menu: 'Inventory',
    submenu: 'Idle Inventory Audit',
    categories: 'Inventory',
    path: '/inventory/audit/history',
  },
  {
    menu: 'Inventory',
    submenu: 'Print-Edit Price Tags',
    categories: 'Inventory',
    path: '/inventory/printeditpricetag',
  },
  {
    menu: 'Inventory',
    submenu: 'New Package Item Search',
    categories: 'Inventory',
    path: '/inventory/packagepricetag',
  },
  {
    menu: 'Inventory',
    submenu: 'Inventory Transfer Receive',
    categories: 'Inventory',
    path: '/inventory/inventorytransfer',
  },
];

const receivePOSubMenu = {
  menu: 'Inventory',
  submenu: 'PO Receiving',
  categories: 'Inventory',
  path: '/receivingpo/purchaseorders',
};

const paymentSubMenu = {
  menu: 'Payment',
  submenu: 'Take Payment',
  categories: 'Payment',
  path: '/payment1/paymentsearch',
};

const submenuTab = [
  {
    menu: 'Payment',
    submenu: 'RAC Pay',
    categories: 'Payment',
    path: '/payment',
  },
  {
    menu: 'Customer',
    submenu: 'Create Customer',
    categories: 'Customer',
    path: '/customer/createcustomer',
  },
  {
    menu: 'Customer',
    submenu: 'Create Agreement',
    categories: 'Agreement',
    //eslint-disable-next-line sonarjs/no-duplicate-string
    path: '/customer/searchcustomer',
  },
  {
    menu: 'Customer',
    submenu: 'Agreement Transfer Receive',
    categories: 'Agreement',
    //eslint-disable-next-line sonarjs/no-duplicate-string
    path: '/agreement/transferreceive',
  },
  {
    menu: AccountManagement,
    submenu: 'Past Due List',
    categories: 'Collections',
    path: '/am/past-due-list',
  },
  {
    menu: AccountManagement,
    submenu: 'AM Activity Log',
    categories: 'Collections',
    path: '/am/activity-log',
  },

  {
    menu: 'Store',
    submenu: 'Daily Activity Planner',
    categories: 'Store',
    path: '/dap',
  },
  {
    menu: 'Store',
    submenu: 'Send Digital Order Form',
    categories: 'Store',
    path: '/storemgmt/digitalorderform',
  },
  {
    menu: 'Store',
    submenu: 'Close End of Day',
    categories: 'Store',
    path: '/storemgmt/closeeod',
  },
  {
    menu: 'Store',
    submenu: 'PIN Management',
    categories: 'Store',
    path: '/storemgmt/pinmanagement',
  },
  {
    menu: 'Store',
    submenu: 'Lookup Store',
    categories: 'Store',
    path: '/storemgmt/lookupstore',
  },
  {
    menu: 'Store',
    submenu: 'Drops',
    categories: 'Cash Management',
    path: '/cashmgmt/drop',
  },
  {
    menu: 'Store',
    submenu: 'Final Drop',
    categories: 'Cash Management',
    path: '/cashmgmt/final/drop',
  },
  {
    menu: 'Store',
    submenu: 'Drop Reconciliation',
    categories: 'Cash Management',
    path: '/cashmgmt/reconcil',
  },
  {
    menu: 'Store',
    submenu: 'Drawer Audit',
    categories: 'Cash Management',
    path: '/cashmgmt/audit',
  },
  {
    menu: 'Store',
    submenu: 'Cash Paid Out of Drawer',
    categories: 'Cash Management',
    path: '/cashmgmt/paidout',
  },
  {
    menu: 'Store',
    submenu: 'Paid In to Drawer',
    categories: 'Cash Management',
    path: '/cashmgmt/paidin',
  },
  {
    menu: 'Store',
    submenu: 'Cash Drop Log',
    categories: 'Cash Management',
    path: '/cashmgmt/cashdrop',
  },
  {
    menu: 'Store',
    submenu: 'Past Drop Receipt',
    categories: 'Cash Management',
    path: '/cashmgmt/pastdrop',
  },
  {
    menu: 'Operations',
    submenu: 'Pricing Uploads',
    categories: 'Pricing',
    path: '/pricing/upload',
  },
  {
    menu: 'Operations',
    submenu: 'Reports',
    categories: 'Pricing',
    path: '/pricing/report',
  },
  {
    menu: 'Operations',
    submenu: 'Reports',
    categories: 'Pricing',
    path: '/pricing/report',
  },
  {
    menu: 'Operations',
    submenu: 'Reports',
    categories: 'Pricing',
    path: '/pricing/report',
  },
  {
    menu: 'Admin',
    submenu: 'Field Support Center',
    categories: 'Admin',
    path: '/storemgmt/fsc/details',
  },
  {
    menu: 'Admin',
    submenu: 'Contest',
    categories: 'Admin',
    path: '/storemgmt/contest/details',
  },
];

const cashManagementMenu = [
  {
    menu: 'Store',
    submenu: 'Drops',
    categories: 'Cash Management',
    path: '/cashmgmt/drop',
  },
  {
    menu: 'Store',
    submenu: 'Final Drop',
    categories: 'Cash Management',
    path: '/cashmgmt/final/drop',
  },
  {
    menu: 'Store',
    submenu: 'Drop Reconciliation',
    categories: 'Cash Management',
    path: '/cashmgmt/reconcil',
  },
  {
    menu: 'Store',
    submenu: 'Drawer Audit',
    categories: 'Cash Management',
    path: '/cashmgmt/audit',
  },
  {
    menu: 'Store',
    submenu: 'Cash Paid Out of Drawer',
    categories: 'Cash Management',
    path: '/cashmgmt/paidout',
  },
  {
    menu: 'Store',
    submenu: 'Paid In to Drawer',
    categories: 'Cash Management',
    path: '/cashmgmt/paidin',
  },
  {
    menu: 'Store',
    submenu: 'Cash Drop Log',
    categories: 'Cash Management',
    path: '/cashmgmt/cashdrop',
  },
  {
    menu: 'Store',
    submenu: 'Past Drop Receipt',
    categories: 'Cash Management',
    path: '/cashmgmt/pastdrop',
  },
];
//eslint-disable-next-line sonarjs/cognitive-complexity
export const Header = (props: any | undefined) => {
  // const classes = useClasses();
  const classes = headerStyles();
  const { activeStore }: any = useContext(UserContext);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [value, setValue] = useState(activeStore);
  const [hideShowForm, setStyle] = useState('');
  const [hideHelper, setHideHelper] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [hideShowStore, showStore] = useState('');
  const [hideShowProfile, showProfile] = useState('');
  const [menuClick, setsubmenu] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [storeNumber, setstoreNumber] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [dropdownDefault, setdefault] = useState(false);
  const [DivActive, setDivActive] = useState('0');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [SubMenuActive, setSubMenuActive] = useState('');
  const [HoverOption, setHoverOption] = useState('');
  const [datesetting, setDatesetting] = useState('');
  const [minDate, setMinDate] = useState('');
  // const [dropdownIndex, setDropdownIndex] = useState<any>();
  const [menuTab, setMenuTab] = useState([]);
  // search fields
  const [firstname, setfirstname] = useState('');
  const [lastname, setlastname] = useState('');
  const [dob, setdob] = useState('');
  const [ssn1, setssn1] = useState('');
  const [ssn2, setssn2] = useState('');
  const [ssn3, setssn3] = useState('');
  const [RacIconNav, setRacIconNav] = useState('/dashboard');
  // const [calendarOp en, setCalendarOpen] = useState(false);
  // const [dropdownId, setDropdownId] = useState('');
  const [phonenumber, setphonenumber]: any = useState();
  const PhoneNumber = parseInt(
    phonenumber != undefined ? phonenumber.replace(/\D/g, '') : ''
  );
  const Phoneinput = PhoneNumber.toString();
  const [dropdown, setstoreDropdown] = useState<any>();
  const history = useHistory();
  const path = window.location.pathname.split('/');
  const acctmgmtPath = path[2] == 'am' ? 'account management' : '';
  const poReceivingPath = path[2] == 'receivingpo' ? 'inventory' : '';
  const manualPOPath = path[2] == manualpo ? 'inventory' : '';
  const inventoryPath = path[2] == 'inventory' ? 'inventory' : '';
  const dapPath = path[2] == 'dap' ? 'store' : '';
  const dofPath =
    path[2] == 'storemgmt' && path[3] !== 'fsc' && path[3] !== 'contest'
      ? 'store'
      : '';
  const agreementPath = path[2] == 'agreement' ? 'customer' : '';
  const payment1Path = path[2] == 'payment1' ? 'payment' : '';
  const pricingPath = path[2] == 'pricing' ? 'operations' : '';
  const CashMgmtPath = path[2] == 'cashmgmt' ? 'store' : '';
  const fscPath = path[3] == 'fsc' ? 'admin' : '';
  const contestPath = path[3] == 'contest' ? 'admin' : '';
  // const previousPermissions = useRef(props.passingData);
  const { logout } = useAuthContext();

  const [subMenuTab, setSubMenuTab] = useState(submenuTab);
  const [helperData, setHelperData]: any = useState({
    whatsNewURL: '',
    jobAidURL: '',
    serviceNowURL: '',
    phoneNumber: '-',
  });
  /* eslint-disable no-console */

  useEffect(() => {
    setMenuTab(props.passingData);
    //eslint-disable-next-line sonarjs/no-duplicate-string
    setDatesetting(moment().subtract(18, 'years').format('YYYY-MM-DD'));
    setMinDate(moment().subtract(110, 'years').format('YYYY-MM-DD'));
    setstoreDropdown(props.dropDownData ? props.dropDownData : []);

    if (
      props !== undefined &&
      props.passingData.length > 0 &&
      props.passingData[0].name === RouteName.Payment
    ) {
      console.log('racnav:payment');
      setRacIconNav('/payment1');
    } else if (props !== undefined && props.passingData.length > 0) {
      console.log('racnav: ', props.passingData[0].path);
      setRacIconNav(props.passingData[0].path);
    }

    // if (dropdown !== undefined && dropdown.length > 0) {
    //   sessionStorage.setItem('storeNumber', dropdown[0].accountingUnitNumber);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.passingData]);
  // eslint-disable-next-line sonarjs/cognitive-complexity

  useEffect(() => {
    const allowedSubRoutes: AllowedSubRoute[] = props.allowedSubRoutes;
    if (allowedSubRoutes && allowedSubRoutes.length > 0) {
      const updatedSubMenu = [...submenuTab];

      const hasPayment = allowedSubRoutes.find(
        (item) => item.path === '/payment1'
      );
      const hasInventory = allowedSubRoutes.find(
        (item) => item.name === RouteName.Inventory
      );
      const hasManualPO = allowedSubRoutes.find(
        (item) => item.name === RouteName.ManualPO
      );
      const hasReceivingPO = allowedSubRoutes.find(
        (item) => item.name === RouteName.ReceivingPO
      );

      if (hasInventory) {
        InventoryPoSubmenu.map((value) => {
          updatedSubMenu.push(value);
        });
      }
      hasPayment && updatedSubMenu.unshift(paymentSubMenu);
      hasManualPO && updatedSubMenu.push(manualPOSubMenu);
      hasReceivingPO && updatedSubMenu.push(receivePOSubMenu);
      /* eslint-disable no-console */
      console.log('Nav cashmgmt', props);
      if (
        props.menuNavPermissions !== undefined &&
        props.menuNavPermissions !== null &&
        props.menuNavPermissions !== '' &&
        props.menuNavPermissions.length > 0
      ) {
        console.log('Nav props.menuNavPermissions', props.menuNavPermissions);
        const menuCashmgmt = props.menuNavPermissions.filter((el: any) => {
          return el == 'RPDCASHMGMT';
        });
        menuCashmgmt.length > 0
          ? cashManagementMenu.map((value) => {
              updatedSubMenu.push(value);
            })
          : '';
      }

      // validate the menu and add the inventory menu items

      setSubMenuTab(updatedSubMenu);
      /* eslint-disable no-console */
      console.log('Nav menu manualPOSubMenu', updatedSubMenu, hasInventory);
      console.log('Nav menu Allowed', allowedSubRoutes, props);
      /* eslint-enable no-console */
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.allowedSubRoutes]);

  useEffect(() => {
    if (dropdown !== undefined && dropdown.length > 0) {
      const currentStore = sessionStorage.getItem('storeNumber');
      for (let i = 0; i < dropdown.length; i++) {
        // eslint-disable-next-line sonarjs/no-collapsible-if
        if (currentStore != null && currentStore != undefined) {
          if (dropdown[i].accountingUnitNumber == currentStore) {
            setdefault(true);
            break;
          }
        } else {
          sessionStorage.setItem(
            'storeNumber',
            dropdown[0].accountingUnitNumber
          );
          sessionStorage.setItem(
            'storeDetails',

            Buffer.from(JSON.stringify(dropdown[0].storeDetails)).toString(
              'base64'
            )
          );
          //location.reload();
        }
      }
      const PageLoad = async () => {
        const configPayload = {
          storeNumbers: [dropdown[0].accountingUnitNumber],
          paramKeyNames: [
            'ReleaseNotesURL',
            'ServiceNowURL',
            'JobAidURL',
            'SupportContactNumber',
          ],
        };
        const configResult = await configCall(configPayload);
        if (configResult) {
          const filterReleaseNotesUrl =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'ReleaseNotesURL'
            );
          const filterSNUrl =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'ServiceNowURL'
            );
          const filterJobAidUrl =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'JobAidURL'
            );
          const filterStorePHNumber =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'SupportContactNumber'
            );
          setHelperData({
            whatsNewURL: filterReleaseNotesUrl[0].paramValue,
            jobAidURL: filterJobAidUrl[0].paramValue,
            serviceNowURL: filterSNUrl[0].paramValue,
            phoneNumber: filterStorePHNumber[0].paramValue,
          });
        }
      };
      PageLoad();
    }
  }, [dropdown]);

  useEffect(() => {
    const ssn1El: any = document.getElementById('a11y_ssn1');
    // eslint-disable-next-line sonarjs/no-collapsible-if
    if (dob < datesetting && dob > minDate) {
      ssn1El.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dob]);

  const handleFirstname = (event: any) => {
    let value = event.target.value;
    value = value.replace(/[^A-Z-',.; a-z]/gi, '');
    setfirstname(value);
  };

  const handleLastname = (event: any) => {
    let value = event.target.value;
    value = value.replace(/[^A-Z-',.; a-z]/gi, '');
    setlastname(value);
  };

  const handleDOB = (event: any) => {
    // eslint-disable-next-line sonarjs/no-duplicate-string
    // if (event != undefined && event != null && event != 'Invalid Date') {
    //   setdob(moment(event).format('YYYY-MM-DD'));
    // } else {
    setdob(event);
    // }
  };

  const handleSSN1 = (event: any) => {
    const ssn2El: any = document.getElementById('a11y_ssn2');
    const ssn1El: any = document.getElementById('a11y_ssn1');
    let value = event.target.value;
    value = value.replace(/[^0-9\b]/gi, '');
    setssn1(value); //eslint-disable-next-line sonarjs/no-collapsible-if
    if (value.length === 3) {
      if (ssn1El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };

  const handleSSN2 = (event: any) => {
    const ssn3El: any = document.getElementById('a11y_ssn3');
    const ssn2El: any = document.getElementById('a11y_ssn2');
    const ssn1El: any = document.getElementById('a11y_ssn1');
    let value = event.target.value;
    value = value.replace(/[^0-9\b]/gi, '');
    setssn2(value); //eslint-disable-next-line sonarjs/no-collapsible-if
    if (value.length == 2) {
      if (ssn2El == document.activeElement) {
        ssn3El.focus();
      }
    } //eslint-disable-next-line sonarjs/no-collapsible-if
    if (value.length == 0 && ssn1.length > 0) {
      if (ssn2El == document.activeElement) {
        ssn1El.focus();
      }
    }
  };

  const handleSSN3 = (event: any) => {
    const ssn3El: any = document.getElementById('a11y_ssn3');
    const ssn2El: any = document.getElementById('a11y_ssn2');
    let value = event.target.value;
    value = value.replace(/[^0-9\b]/gi, '');
    setssn3(value); //eslint-disable-next-line sonarjs/no-collapsible-if
    if (value.length == 0 && ssn2.length > 0) {
      if (ssn3El == document.activeElement) {
        ssn2El.focus();
      }
    }
  };

  const handlePhonenumber = (event: any) => {
    setphonenumber(event.target.value);
  };

  const searchForm = () => {
    hideShowForm == '' ? setStyle('show') : setStyle('');
    showStore('');
    showProfile('');
    setSubMenuActive('');
    // setDivActive('');
    if (hideShowForm == '') {
      setfirstname('');
      setlastname('');
      setdob('');
      setssn1('');
      setssn2('');
      setssn3('');
      setphonenumber('');
    }
  };

  useEffect(() => {
    document.getElementById('lastnametextbox')?.focus();
  }, [hideShowForm]);

  const Profile = () => {
    hideShowProfile == '' ? showProfile('show') : showProfile('');
    setStyle('');
    showStore('');
  };

  const helperClicked = () => {
    hideHelper == '' ? setHideHelper('show') : setHideHelper('');
    setStyle('');
    showStore('');
  };

  const submenuDisplay = (e: any, group: any) => {
    menuClick == '' ? setsubmenu('show') : setsubmenu('');
    const DivID = e.target.id;
    // eslint-disable-next-line no-console
    console.log('Test Menu Details', DivID, e, group);
    if (DivID !== DivActive || group.length === 0) {
      setDivActive(DivID);
      setSubMenuActive(DivID);
    } else {
      setDivActive('');
      setSubMenuActive('');
    }
    setStyle('');
    showStore('');
    showProfile('');
    //const obj1=menuTab[_index];
  };

  const submenudisplaynone = () => {
    setSubMenuActive('');
    setDivActive('');
  };

  const SubMenuOptionClick = () => {
    const DivId = HoverOption;
    setSubMenuActive('');
    setDivActive(DivId);
    // eslint-disable-next-line no-console
    console.log('Inside SubMenu Option', DivId);
  };

  const storeDisplay = (e: any) => {
    const storeNum = e.target.innerText.split(' ');

    // setDropdownId(e.target.id);
    // setdefault(true);
    // const obj = dropdown[_index];
    if (storeNum == undefined || storeNum[2] == undefined) {
      setValue('');
    } else {
      const storeIndex = dropdown.findIndex(
        (e: any) => e.accountingUnitNumber == storeNum[2]
      );
      setValue(storeNum[2]);
      sessionStorage.setItem('storeNumber', storeNum[2]);

      sessionStorage.setItem(
        'storeDetails',
        Buffer.from(JSON.stringify(dropdown[storeIndex].storeDetails)).toString(
          'base64'
        )
      );
      history.push({
        pathname: RacIconNav,
      });
      const PageLoad = async () => {
        const configPayload = {
          storeNumbers: [storeNum[2]],
          paramKeyNames: [
            'ReleaseNotesURL',
            'ServiceNowURL',
            'JobAidURL',
            'SupportContactNumber',
          ],
        };
        const configResult = await configCall(configPayload);
        if (configResult) {
          const filterReleaseNotesUrl =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'ReleaseNotesURL'
            );
          const filterSNUrl =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'ServiceNowURL'
            );
          const filterJobAidUrl =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'JobAidURL'
            );
          const filterStorePHNumber =
            configResult.storeProfileResponse.configDetails[0].configDetails.filter(
              (el: any) => el.paramKeyName == 'SupportContactNumber'
            );
          setHelperData({
            whatsNewURL: filterReleaseNotesUrl[0].paramValue,
            jobAidURL: filterJobAidUrl[0].paramValue,
            serviceNowURL: filterSNUrl[0].paramValue,
            phoneNumber: filterStorePHNumber[0].paramValue,
          });
        }
      };
      PageLoad();
    }
    setstoreNumber(storeNum[2]);
    showStore('');
  };

  const submenuDisplayOnMouseOver = (e: any) => {
    const HoverID = e.target.id;
    // eslint-disable-next-line no-console
    console.log('Inside hoverid function', HoverID);
    setHoverOption(HoverID);
  };

  const UserDetails = () => {
    /* eslint-disable no-console */
    console.log('Firstname value from menu', firstname);
    history.push({
      pathname: '/customer/searchcustomer',
      state: {
        FirstName: firstname,
        LastName: lastname,
        DOB: dob,
        PhoneNumber: phonenumber.replace(/\D/g, ''),
        SSN1: ssn1,
        SSN2: ssn2,
        SSN3: ssn3,
      },
    });
    console.log('Firstname value from menu', firstname);
    setStyle('');
    //location.reload();
  };

  const searchOutsideClick = () => {
    // if (!calendarOpen) {
    setStyle('');
    // }
  };

  const storeOutsideClick = () => {
    showStore('');
  };

  const profileOutsideClick = () => {
    showProfile('');
  };

  const outsideHelperClicked = () => {
    setHideHelper('');
  };

  const bindDropDown = () => {
    return (
      <Grid
        className={`${classes.racDropdown} ${
          dropdown.length !== 1 ? classes.dropWidth : null
        }`}
      >
        <OutsideClickHandler onOutsideClick={storeOutsideClick}>
          {dropdown.length !== 1 ? (
            // <RACSearchDropdown
            //   options={dropdownStrap()}
            //   OnChange={(e) => storeDisplay(e)}
            //   defaultInputValue={
            //     window.sessionStorage.getItem('storeNumber') !== undefined &&
            //     window.sessionStorage.getItem('storeNumber') !== null &&
            //     window.sessionStorage.getItem('storeNumber') !== ''
            //       ? `Store - ${window.sessionStorage.getItem('storeNumber')}`
            //       : `Store - ${dropdown[0].accountingUnitNumber}`
            //   }
            //   backgroundColorCondition={true}
            //   backgroundColor="#f2f2ff !important"
            //   className={classes.searchableDrop}
            //   borderCondition={true}
            //   borderColor="thin solid #e3e3e3"
            //   fontColor="#0f0fbf !important"
            //   AccordionColor="#0f0fbf !important"
            //   value={value}
            //   disabled={dropdown.length === 1 ? true : false}
            // ></RACSearchDropdown>
            <RACSearchSelect
              options={dropdownStrap()}
              backgroundColorCondition={true}
              backgroundColor="#f2f2ff"
              inputRootClass={classes.dropheight}
              value={
                window.sessionStorage.getItem('storeNumber') !== undefined &&
                window.sessionStorage.getItem('storeNumber') !== null &&
                window.sessionStorage.getItem('storeNumber') !== ''
                  ? `Store - ${window.sessionStorage.getItem('storeNumber')}`
                  : value != ''
                  ? `Store - ${value}`
                  : ''
              }
              onChange={(e) => storeDisplay(e)}
              rootClass={classes.DropBorder}
              inputClass={classes.textFont}
              IconClassName={classes.IconClass}
              optionClass={classes.dropdownitemstyle}
              onKeyDown={(event) => (event.defaultMuiPrevented = true)}
            ></RACSearchSelect>
          ) : (
            <Typography className={classes.singleStore}>
              Store - {dropdown[0].accountingUnitNumber}
            </Typography>
          )}
        </OutsideClickHandler>
      </Grid>
    );
  };

  const dropdownStrap = () => {
    if (dropdown !== undefined) {
      return dropdown.map((obj: any) => {
        return {
          label: 'Store - ' + obj.accountingUnitNumber,
          value: obj.accountingUnitNumber,
        };
      });
    }
  };

  const nodataBind = () => {
    return (
      <Grid className={classes.racDropdown}>
        <Typography className={classes.singleStore}>No Stores</Typography>
      </Grid>
    );
  };

  const searchEnter = (
    event: any,
    firstname: string,
    lastname: string,
    dob: string,
    ssn1: string,
    ssn2: string,
    ssn3: string // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (event.charCode === 13) {
      event.preventDefault();
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length >= 3 &&
        lastname.length != 0 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length != 0 &&
        lastname.length >= 3 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length == 0 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length == 0 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob == '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length == 0 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      //new add
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput == 'NaN'
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob == '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob != '' &&
        ssn1 == '' &&
        ssn2 == '' &&
        ssn3 == '' &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length >= 3 &&
        lastname.length == 0 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length == 0 &&
        lastname.length >= 3 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
      if (
        firstname.length + lastname.length >= 3 &&
        dob != '' &&
        ssn1 != '' &&
        ssn2 != '' &&
        ssn3 != '' &&
        ssn3.length == 4 &&
        ssn2.length == 2 &&
        ssn1.length == 3 &&
        Phoneinput.length == 10
      ) {
        UserDetails();
      }
    }
  };
  const SearchBtn = (
    firstname: string,
    lastname: string,
    dob: string,
    ssn1: string,
    ssn2: string,
    ssn3: string // eslint-disable-next-line sonarjs/cognitive-complexity
  ) => {
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length != 0 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length != 0 &&
      lastname.length >= 3 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length == 0 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length == 0 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob == '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length == 0 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput == 'NaN'
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob == '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob != '' &&
      ssn1 == '' &&
      ssn2 == '' &&
      ssn3 == '' &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length >= 3 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length >= 3 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length == 0 &&
      lastname.length == 0 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    }
    if (
      firstname.length + lastname.length >= 3 &&
      dob != '' &&
      ssn1 != '' &&
      ssn2 != '' &&
      ssn3 != '' &&
      ssn3.length == 4 &&
      ssn2.length == 2 &&
      ssn1.length == 3 &&
      Phoneinput.length == 10
    ) {
      return (
        <a
          id="search-btn-close"
          data-testid="btnvalidate"
          className={classes.racSearchPrimary}
          onClick={UserDetails}
        >
          Search
        </a>
      );
    } else {
      return (
        <a
          id="search-btn-close"
          data-testid="btndisabled"
          className={classes.racSearchPrimarydisabled}
        >
          Search
        </a>
      );
    }
  };

  function groupBy(objectArray: any, property: any) {
    return objectArray.reduce((acc: any, obj: any) => {
      const key = obj[property];
      if (!acc[key]) {
        acc[key] = [];
      }
      // Add object to list for given key's value
      acc[key].push(obj);
      return acc;
    }, {});
  }

  const redirectionClicked = (invoker: any) => {
    if (invoker == 'New Feature' && helperData.whatsNewURL !== '') {
      window.open(helperData.whatsNewURL, '_blank');
    } else if (invoker == 'Job Aids' && helperData.jobAidURL !== '') {
      window.open(helperData.jobAidURL, '_blank');
    } else if (invoker == 'SN Portal' && helperData.serviceNowURL !== '') {
      window.open(helperData.serviceNowURL, '_blank');
    }
  };

  // const maxiDate = new Date();
  // maxiDate.setFullYear(maxiDate.getFullYear() - 18);

  // const openCalendar = () => {
  //   setCalendarOpen(true);
  // };

  // const closeCalendar = () => {
  //   setCalendarOpen(false);
  // };

  return (
    <div className="App">
      <Grid
        classes={{ root: clsx(classes.racNavbarExpand, classes.racFixedTop) }}
      >
        <Grid className={classes.racContainerFluid}>
          {/*RAC LOGO*/}
          <Link to={RacIconNav} className={classes.racNavBarBrand}>
            <RACLogo className={classes.racLogoResponsive}></RACLogo>
          </Link>
          {/*RAC LOGO ENDS*/}
          <Grid
            classes={{
              root: clsx(classes.racNavbarCollapse, classes.ContentEnd),
            }}
            id="navbarSupportedContent"
          >
            {/*Menu Items Starts Here*/}
            {props.passingData !== undefined ? (
              <List className={classes.racNavbarNav}>
                {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
                {/* {props.passingData.length > 0 */}
                {/* eslint-disable-next-line sonarjs/cognitive-complexity */}
                {menuTab.map((value: any, index: any) => {
                  const group = subMenuTab.filter(
                    (data) =>
                      data.menu ===
                      (value.name === 'Agreement' ? 'Customer' : value.name)
                  );
                  const subgroup = groupBy(group, 'categories');
                  const category = Object.keys(subgroup);
                  return (
                    <ListItem key="menu" className={classes.racNavBar}>
                      {group.length === 0 ? (
                        <NavLink
                          id={index.toString()}
                          activeClassName={classes.racNavMenuActive}
                          className={classes.racNavMenu}
                          to={value.path.toLowerCase()}
                          onMouseOver={(e) => submenuDisplayOnMouseOver(e)}
                          onClick={(e) => submenuDisplay(e, group)}
                        >
                          {value.name === 'Agreement' ? 'Customer' : value.name}
                        </NavLink>
                      ) : (
                        <NavLink
                          to="#"
                          id={index.toString()}
                          activeClassName={clsx({
                            [classes.racNavMenuActive]:
                              path[2] == value.name.toLowerCase() ||
                              acctmgmtPath == value.name.toLowerCase() ||
                              poReceivingPath == value.name.toLowerCase() ||
                              manualPOPath == value.name.toLowerCase() ||
                              dapPath == value.name.toLowerCase() ||
                              dofPath == value.name.toLowerCase() ||
                              pricingPath == value.name.toLowerCase() ||
                              agreementPath == value.name.toLowerCase() ||
                              inventoryPath == value.name.toLowerCase() ||
                              payment1Path == value.name.toLowerCase() ||
                              CashMgmtPath == value.name.toLowerCase() ||
                              fscPath == value.name.toLowerCase() ||
                              contestPath == value.name.toLowerCase(),
                          })}
                          onClick={(e) => submenuDisplay(e, group)}
                          onMouseOver={(e) => submenuDisplayOnMouseOver(e)}
                          onBlur={() => submenudisplaynone()}
                          className={classes.racDropdownToggle}
                        >
                          {' '}
                          {value.name === 'Agreement' ? 'Customer' : value.name}
                        </NavLink>
                      )}
                      {group.length !== 0 ? (
                        <Grid
                          id={index.toString()}
                          classes={{
                            root: clsx(
                              classes.racDropdownMenu,
                              classes.racMenuContent
                            ),
                          }}
                          className={clsx({
                            [classes.displaySubmenu]:
                              SubMenuActive === index.toString(),
                          })}
                          aria-labelledby="navbarpayment"
                        >
                          <Grid className={classes.racMegaMenu}>
                            <Grid container>
                              {category.map((data) => {
                                const filteredGroup = group.filter(
                                  (result) => result.categories == data
                                );
                                const categoryLength = category.length;
                                const submenuLength = filteredGroup.length;
                                // {
                                //   AssignClassName(
                                //     categoryLength,
                                //     submenuLength
                                //   );
                                // }
                                return (
                                  <Grid
                                    key=""
                                    className={clsx({
                                      [classes.racColMD12]: categoryLength <= 1,
                                      [classes.racColMD6]:
                                        (categoryLength == 2 &&
                                          submenuLength == 2) ||
                                        (categoryLength == 2 &&
                                          submenuLength == 1),
                                      [classes.racColMD2]:
                                        categoryLength == 4 &&
                                        submenuLength == 3,
                                      [classes.racColMD4]:
                                        categoryLength == 4 &&
                                        submenuLength == 6,
                                      [classes.racColMD3]:
                                        categoryLength == 4 &&
                                        submenuLength == 2,
                                      [classes.racColMD5]:
                                        categoryLength == 2 &&
                                        submenuLength == 6,
                                      [classes.racColMD7]:
                                        categoryLength == 2 &&
                                        submenuLength >= 7,
                                    })}
                                  >
                                    <span className={classes.menuSubHeader}>
                                      {data}
                                    </span>

                                    <Grid container className="">
                                      {filteredGroup.map((val, ind) => {
                                        return (
                                          <Grid
                                            item
                                            key=""
                                            className={clsx({
                                              [classes.racColMD3]:
                                                categoryLength <= 1,
                                              [classes.racColMD6]:
                                                (categoryLength == 2 &&
                                                  submenuLength == 2) ||
                                                (categoryLength == 2 &&
                                                  submenuLength == 1),
                                              [classes.racColMD12]:
                                                categoryLength == 4 &&
                                                submenuLength == 3,
                                              [classes.racColMD6]:
                                                categoryLength == 4 &&
                                                submenuLength == 6,
                                              [classes.racColMD12]:
                                                categoryLength == 4 &&
                                                submenuLength == 2,
                                              [classes.racColMD6]:
                                                categoryLength == 2 &&
                                                submenuLength == 6,
                                              [classes.racColMD4]:
                                                categoryLength == 2 &&
                                                submenuLength >= 7,
                                            })}
                                          >
                                            <Link
                                              id={ind.toString()}
                                              onClick={() =>
                                                SubMenuOptionClick()
                                              }
                                              className={classes.navMenu}
                                              to={val.path || '#'}
                                            >
                                              {val.submenu}
                                            </Link>
                                          </Grid>
                                        );
                                      })}
                                    </Grid>
                                  </Grid>
                                );
                              })}
                            </Grid>
                          </Grid>
                        </Grid>
                      ) : null}
                    </ListItem>
                  );
                })}
              </List>
            ) : null}
            {/*Menu Items Ends Here*/}
            {/*Right Widget Part Starts Here*/}
            <Grid className={classes.dFlex}>
              {path[3] !== 'searchcustomer' ? (
                <Link
                  to="#"
                  data-testid="searchIcon"
                  className={clsx({
                    [classes.racGlobalSearchBtnShow]: hideShowForm === 'show',
                    [classes.racGlobalSearchBtn]: hideShowForm === '',
                    [classes.cursorDefault]:
                      path[2] == 'customer' && path[3] == 'searchcustomer',
                  })}
                  id="navbarglobalsearch"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  onClick={searchForm}
                ></Link>
              ) : null}
              <OutsideClickHandler onOutsideClick={searchOutsideClick}>
                <Grid
                  data-testid="searchbtn"
                  onKeyPress={(event: any) =>
                    searchEnter(
                      event,
                      firstname,
                      lastname,
                      dob,
                      ssn1,
                      ssn2,
                      ssn3
                    )
                  }
                  className={clsx({
                    [classes.racGlobalSearchShow]: hideShowForm === 'show',
                  })}
                  classes={{
                    root: clsx(classes.racDropdownMenu, classes.racMenuContent),
                  }}
                  aria-labelledby="navbarglobalsearch"
                >
                  <Grid
                    container
                    spacing={2}
                    className={classes.marginLeftSpacer}
                  >
                    <Grid item xs="auto">
                      {/* <label htmlFor="a11y_fn" className="form-label">
                        First Name
                      </label> */}
                      <Typography
                        variant="body2"
                        component="label"
                        className={classes.formLabel}
                      >
                        Last Name
                      </Typography>
                      <Grid className={classes.formInputAlign}>
                        <RACTextbox
                          maxlength={30}
                          isCurrency={false}
                          id="lastnametextbox"
                          type="text"
                          value={lastname}
                          OnChange={(event) => handleLastname(event)}
                        ></RACTextbox>
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      {/* <label htmlFor="a11y_ln" className="form-label">
                        Last Name
                      </label> */}
                      <Typography
                        variant="body2"
                        component="label"
                        className={classes.formLabel}
                        // eslint-disable-next-line sonarjs/no-duplicate-string
                      >
                        First Name
                      </Typography>
                      <Grid className={classes.formInputAlign}>
                        <RACTextbox
                          isCurrency={false}
                          maxlength={30}
                          type="text"
                          value={firstname}
                          OnChange={(event) => handleFirstname(event)}
                        ></RACTextbox>
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      <Typography
                        variant="body2"
                        component="label"
                        className={classes.formLabel}
                      >
                        Date of Birth
                      </Typography>
                      <Grid className={classes.formInputAlignDate}>
                        <RACDatePicker
                          label=""
                          value={dob}
                          onChange={handleDOB}
                          inputProps={{ max: datesetting, min: minDate }}
                        ></RACDatePicker>
                        {/* <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            value={dob}
                            onChange={handleDOB}
                            renderInput={(params) => <TextField {...params} />}
                            maxDate={maxiDate}
                            onOpen={openCalendar}
                            onClose={closeCalendar}
                            allowSameDateSelection
                          />
                        </LocalizationProvider> */}
                      </Grid>
                    </Grid>
                    <Grid item md={2}>
                      <Typography
                        variant="body2"
                        component="label"
                        className={classes.formLabel}
                      >
                        Social Security# / ITIN#
                      </Typography>
                      <Grid
                        container
                        spacing={2}
                        className={classes.formInputAlign}
                      >
                        <Grid item md={4} className={classes.PT0PB0}>
                          {/* <input
                              type="password"
                              data-testid="ssn1test"
                              value={ssn1}
                              maxLength={3}
                              onChange={(event) => handleSSN1(event)}
                              className="form-control"
                              id="a11y_ssn1"
                            /> */}
                          <RACTextbox
                            maxlength={3}
                            isCurrency={false}
                            type="password"
                            value={ssn1}
                            className={classes.textCenterSSN}
                            OnChange={(event) => handleSSN1(event)}
                            id="a11y_ssn1"
                          ></RACTextbox>
                        </Grid>
                        <Grid item md={3} className={classes.PT0PB0}>
                          {/* <input
                              type="password"
                              data-testid="ssn2test"
                              value={ssn2}
                              maxLength={2}
                              onChange={(event) => handleSSN2(event)}
                              className="form-control"
                              id="a11y_ssn2"
                            /> */}
                          <RACTextbox
                            maxlength={2}
                            isCurrency={false}
                            type="password"
                            value={ssn2}
                            OnChange={(event) => handleSSN2(event)}
                            className={classes.textCenterSSN}
                            id="a11y_ssn2"
                          ></RACTextbox>
                        </Grid>
                        <Grid item md={5} className={classes.PT0PB0}>
                          {/* <input
                              type="text"
                              data-testid="ssn3test"
                              value={ssn3}
                              maxLength={4}
                              onChange={(event) => handleSSN3(event)}
                              className="form-control"
                              id="a11y_ssn3"
                            /> */}
                          <RACTextbox
                            maxlength={4}
                            isCurrency={false}
                            type="text"
                            value={ssn3}
                            OnChange={(event) => handleSSN3(event)}
                            className={classes.textCenterSSN}
                            id="a11y_ssn3"
                          ></RACTextbox>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      {/* <label htmlFor="a11y_phonenumber" className="form-label">
                        Phone Number
                      </label> */}
                      <Typography
                        variant="body2"
                        component="label"
                        className={classes.formLabel}
                      >
                        Phone Number
                      </Typography>
                      {/* <PhoneInput
                          type="text"
                          data-testid="phonenumbertest"
                          country="US"
                          name="phone_number"
                          className="form-control"
                          id="Phone"
                          pattern="\d*"
                          maxLength={14}
                          value={phonenumber}
                          onChange={setphonenumber}
                        /> */}
                      <Grid className={classes.formInputAlign}>
                        <RACTextbox
                          isCurrency={false}
                          type="phoneno"
                          value={phonenumber}
                          maxlength={14}
                          OnChange={(event) => handlePhonenumber(event)}
                          pattern="\D*"
                        ></RACTextbox>
                      </Grid>
                    </Grid>
                    <Grid item xs="auto">
                      {SearchBtn(firstname, lastname, dob, ssn1, ssn2, ssn3)}
                    </Grid>
                  </Grid>
                </Grid>
              </OutsideClickHandler>
              {dropdown !== undefined && dropdown.length > 0
                ? bindDropDown()
                : nodataBind()}
              {/* {nodataBind()} */}
              <Grid
                className={classes.racDropdown}
                data-testid="profilebtn"
                onClick={helperClicked}
              >
                <a
                  data-testid="logoutbtn"
                  className={hideHelper === 'show' ? 'racUser show' : 'racUser'}
                  id="profilemenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className={classes.helperIconStyle}
                    src={Helper}
                    alt="search-icon"
                  />
                </a>
                <OutsideClickHandler onOutsideClick={outsideHelperClicked}>
                  <MenuList
                    data-testid="logouttest"
                    className={clsx({
                      [classes.racGlobalSearchShow]: hideHelper === 'show',
                    })}
                    classes={{
                      root: clsx(
                        classes.racProfileContent,
                        classes.racProfileMenu,
                        classes.helperList
                      ),
                    }}
                    aria-labelledby="profilemenu"
                  >
                    <MenuItem
                      className={`${classes.menuItem} ${classes.cursorDefault}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={3}>
                          <Typography className={classes.helpText}>
                            Help
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      className={`${classes.menuItem}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={2}>
                          <img
                            className={classes.racStandardInfoIcon}
                            src={NewFeature}
                            alt="new-icon"
                            onClick={() => redirectionClicked('New Feature')}
                          />
                        </Grid>
                        <Grid item md={9}>
                          <Typography
                            onClick={() => redirectionClicked('New Feature')}
                          >
                            What's New
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      className={`${classes.menuItem}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={2}>
                          <img
                            className={classes.racStandardInfoIcon}
                            src={JobAid}
                            alt="job-icon"
                            onClick={() => redirectionClicked('Job Aids')}
                          />
                        </Grid>
                        <Grid item md={9}>
                          <Typography
                            onClick={() => redirectionClicked('Job Aids')}
                          >
                            Job Aids
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      className={`${classes.menuItem}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={2}>
                          <img
                            className={classes.racStandardInfoIcon}
                            src={Settings}
                            alt="settings-icon"
                            onClick={() => redirectionClicked('SN Portal')}
                          />
                        </Grid>
                        <Grid item md={9}>
                          <Typography
                            onClick={() => redirectionClicked('SN Portal')}
                          >
                            ServiceNow Portal
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      className={`${classes.menuItem} ${classes.cursorDefault}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={2}>
                          <img
                            className={classes.racStandardInfoIcon}
                            src={Phone}
                            alt="phone-icon"
                          />
                        </Grid>
                        <Grid item md={9}>
                          <Typography>{helperData.phoneNumber}</Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </MenuList>
                </OutsideClickHandler>
              </Grid>
              <Grid
                className={classes.racDropdown}
                data-testid="profilebtn"
                onClick={Profile}
              >
                <a
                  data-testid="logoutbtn"
                  className={
                    hideShowProfile === 'show' ? 'racUser show' : 'racUser'
                  }
                  id="profilemenu"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    className={classes.racStandardIcon}
                    src={userImage}
                    alt="search-icon"
                  />
                </a>
                <OutsideClickHandler onOutsideClick={profileOutsideClick}>
                  <MenuList
                    data-testid="logouttest"
                    className={clsx({
                      [classes.racGlobalSearchShow]: hideShowProfile === 'show',
                    })}
                    classes={{
                      root: clsx(
                        classes.racProfileContent,
                        classes.racProfileMenu,
                        classes.menuList
                      ),
                    }}
                    aria-labelledby="profilemenu"
                  >
                    <MenuItem
                      className={`${classes.menuItem} ${classes.cursorDefault}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={3}>
                          <Typography>First Name:</Typography>
                        </Grid>
                        <Grid item md={8}>
                          <Typography>
                            {props != undefined &&
                            props.userData != undefined &&
                            props.userData != {} &&
                            props.userData.firstName != undefined
                              ? props.userData.firstName
                              : ''}
                          </Typography>
                        </Grid>
                        <Grid item md={1}>
                          <CloseIcon className={classes.closeIcon} />
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      className={`${classes.menuItem} ${classes.cursorDefault}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={3}>
                          <Typography>Last Name:</Typography>
                        </Grid>
                        <Grid item md={9}>
                          <Typography>
                            {props != undefined &&
                            props.userData != undefined &&
                            props.userData != {} &&
                            props.userData.lastName != undefined
                              ? props.userData.lastName
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    <MenuItem
                      className={`${classes.menuItem} ${classes.cursorDefault}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={3}>
                          <Typography>Email ID:</Typography>
                        </Grid>
                        <Grid item md={9}>
                          <Typography>
                            {props != undefined &&
                            props.userData != undefined &&
                            props.userData != {} &&
                            props.userData.email != undefined
                              ? props.userData.email
                              : ''}
                          </Typography>
                        </Grid>
                      </Grid>
                    </MenuItem>
                    {props != undefined &&
                    props.userRole != undefined &&
                    props.userRole != '' ? (
                      <MenuItem
                        className={`${classes.menuItem} ${classes.cursorDefault}`}
                        disableRipple
                        disableTouchRipple
                      >
                        <Grid container>
                          <Grid item md={3}>
                            <Typography>Role:</Typography>
                          </Grid>
                          <Grid item md={9}>
                            <Typography>
                              {props != undefined && props.userRole != undefined
                                ? props.userRole
                                : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      </MenuItem>
                    ) : null}
                    <MenuItem
                      className={`${classes.menuItem} ${classes.btnAlign}`}
                      disableRipple
                      disableTouchRipple
                    >
                      <Grid container>
                        <Grid item md={12}>
                          <RACButton
                            color="primary"
                            variant="contained"
                            onClick={logout}
                          >
                            Logout
                          </RACButton>
                        </Grid>
                      </Grid>
                    </MenuItem>
                  </MenuList>
                </OutsideClickHandler>
              </Grid>
            </Grid>
            {/*Right Widget Part Ends Here*/}
          </Grid>
        </Grid>
      </Grid>

      {/*Nav Bar Ends Here*/}
    </div>
  );
};
