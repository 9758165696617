/* eslint-disable no-console */
import { client } from './client';
import { appConfig } from '../config/app-config';

// const QAToken =
//   'eyJraWQiOiI3M1BNSjFNQm1ObkVUU3hPZFNqYWVibVRFK0tjZTRvdVwva1ZDT2VNTGgwbz0iLCJhbGciOiJSUzI1NiJ9.eyJzdWIiOiIydm42dGV0MmhxYmpmbW00ZzdrdDFlNWw4NyIsInRva2VuX3VzZSI6ImFjY2VzcyIsInNjb3BlIjoicmFjXC9yZWFkIiwiYXV0aF90aW1lIjoxNjM1Nzg3NDgzLCJpc3MiOiJodHRwczpcL1wvY29nbml0by1pZHAudXMtZWFzdC0xLmFtYXpvbmF3cy5jb21cL3VzLWVhc3QtMV84TWJPeUVZRzYiLCJleHAiOjE2MzU3OTEwODMsImlhdCI6MTYzNTc4NzQ4MywidmVyc2lvbiI6MiwianRpIjoiMTU3ZTVlMzgtNGVjNi00OWY4LWFkOTItYmE4YjQwMDUwMDI5IiwiY2xpZW50X2lkIjoiMnZuNnRldDJocWJqZm1tNGc3a3QxZTVsODcifQ.lXiLdvWqKa9hykGWOKFvPRq1KlMP-_jicZiqWurE0_-QXFhvxECfUKke7wZ5sdYuVK5uFID2Re4yCWQj60Dp5eAsQ3ue3syF52Qigavz_HexLml88Zm-bf3EtpseGPKsbCORFqxF6nO9IMbKtuqvHk9TRba1G-DHQKzlsepTY6zIZn7c1_a0MD7Bid5F6uvBdUQszdZsPrAx3ksk1oY8Ii8Pi1-Dxy1Np6DmtBCsKIUWfTEmt56-YwjUZlYhB1ojcHbnChb8SEJNJ3-UoVDy7OhCpO2yItsaQ5Nvhux63GMy9WUCsMYLH_iw_FSI2RVgSphkorhTLqiGTeRJz5FkFQ';

export const getmenuandstore = async (parameters: any) => {
  return await client(
    'menu-and-stores',
    { method: 'POST', body: parameters },
    appConfig.apiUrls.micro,
    true
  );
};

export const getCurrentUser = async () => {
  return await client(
    'users/current',
    { method: 'GET' },
    appConfig.apiUrls.container
  );
};

export const getUserInfo = () =>
  client('user/current', { method: 'GET' }, appConfig.apiUrls.micro);

/* Added for POWER BI Report POC */
export const getEmbedInfo = async () => {
  return await client(
    'agreement/update/return',
    { method: 'POST' },
    'https://dev-agreement-racpad.rentacenter.com/api'
  );
};

export const configCall = async (parameters: any) => {
  console.log('appConfig.apiUrls.agreement', appConfig.apiUrls.agreement);
  console.log('appConfig logger', appConfig);

  return await client(
    `agreement/address/eventId`,
    { method: 'POST', body: parameters },
    'https://local-agreement-racpad.rentacenter.com/api',
    true
  );
};
