/* eslint-disable no-console */

import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';
import jwtDecode from 'jwt-decode';
// import { ApiUrls, appConfig } from '../config/app-config';
const storeNumber = window.sessionStorage.getItem('storeNumber');

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();
    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

async function getAccessToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getAccessToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  apiUrl: any,
  // access token is required for accessing AM APIs, but not for general ones (e.g. user APIs)
  needAccessToken?: boolean
) {
  try {
    const jwtToken = await getJWToken();

    const authToken = `Bearer ${jwtToken}`;
    const userInfo: any = jwtDecode(authToken);
    const employeeId = userInfo['custom:employeeId'];

    const slicedEmployeeId = employeeId.slice(0, -3);

    const finalStore: any = slicedEmployeeId.padStart(5, '0');

    const headers: any = {
      'Content-Type': 'application/json; charset=UTF-8',
      storeNumber:
        storeNumber == null || storeNumber == '' ? finalStore : storeNumber,
    };

    if (jwtToken) {
      headers.Authorization = authToken;
    }
    // if (AccessToken !== undefined) {
    //   headers.AccessToken = AccessToken;
    // }

    if (needAccessToken) {
      const accessToken = await getAccessToken();
      headers.AccessToken = accessToken;
    }

    const requestConfig: any = {
      method: requestType,
      ...customConfig,
      headers: {
        ...headers,
        ...customConfig.headers,
      },
    };

    if (body) {
      requestConfig.data = JSON.stringify(body);
    }
    console.log('apiUrl in client', apiUrl);

    return axios(`${apiUrl}/${endpoint}`, requestConfig).then(
      (response: any) => response.data
    );
  } catch (e: any) {
    return e.response;
  }
}
export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
