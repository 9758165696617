export enum RoutePath {
  Index = '/',
  Dashboard = '/dashboard',
  Payment = '/payment',
  Payment1 = '/payment1',
  AccountManagement = '/am',
  Reports = '/reports',
  Customer = '/customer',
  Inventory = '/inventory',
  Store = '/storemgmt',
  Agreement = '/agreement',
  ManualPO = '/manual-po',
  ManualPORedesign = '/manualpo',
  Configurations = '/configs',
  DailyActivityPlanner = '/dap',
  Settings = '/settings',
  NotFound = '/not-found',
  DOF = '/digitalorderform',
  Pricing = '/pricing',
  ReceivingPO = '/receivingpo',
  Drop = '/cashmgmt',
}

export enum RouteName {
  Index = 'Dashboard',
  Payment = 'Payment',
  AccountManagement = 'Account Management',
  Reports = 'Reports',
  Customer = 'Customer',
  Inventory = 'Inventory',
  Store = 'Store',
  Agreement = 'Agreement',
  Dashboard = 'Dashboard',
  Pricing = 'Pricing',
  Operations = 'Operations',
  ManualPO = 'Manual Purchase Order',
  Configurations = 'Admin',
  DailyActivityPlanner = 'Daily Activity Planner',
  Settings = 'Settings',
  HelpCenter = 'Help Center',
  DOF = 'Send Digital Order Form',
  ReceivingPO = 'PO Receiving',
  Drop = 'Drops',
  Admin = 'Admin',
}
