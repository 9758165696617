export enum NavMenuPermission {
  ACCESS_DASH = 'RPDDASH',
  ACCESS_PAY = 'RPDPAY',
  ACCESS_CUSTOMERS = 'RPDCUST',
  ACCESS_AGR = 'RPDAGR',
  ACCESS_ACCTMGMT = 'RACPAD_ACCTMGMT',
  ACCESS_INV = 'RPDINV',
  ACCESS_REPORTS = 'RPDREPORTS',
  ACCESS_STRMNGT = 'RPDSTRMNGT',
  ACCESS_MANUAL_PO = 'RPDMANPO',
  ACCESS_PAYMENT = 'RPDPAYMENT',
  ACCESS_CONFIGURATIONS = 'OWNRCNFG',
  ACCESS_ACCOUNT_MANAGEMENT = 'RACPAD_ACCTMGMT',
  ACCESS_DAILY_ACTIVITY_PLANNER = 'RPDDAP',
  ACCESS_DOF = 'RPDDOF',
  ACCESS_PRICING = 'RPDPRC',
  ACCESS_PO_RECEIVING = 'RPDPORECEIVING',
  ACCESS_DROP = 'RPDSTRMNGT',
  ACCESS_FINAL_DROP = 'RPDFINALDROP',
  ACCESS_PAID_IN = 'RPDPAIDIN',
  ACCESS_PAID_OUT = 'RPDPAIDOUT',
  ACCESS_RECONCIL = 'RPDRECONCIL',
  ACCESS_DRAWER_AUDIT = 'RPDDRAWERAUDIT',
  ACCESS_PAST_DROP_LOG = 'RPDPASTDROPRECEIPT',
  ACCESS_CASH_DROP_LOG = 'RPDCASHDROPLOG',
  ACCESS_ADMIN = 'RPDADMIN',
}
