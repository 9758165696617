/* eslint-disable no-console */
import React from 'react';
import { Router } from 'react-router';

import {
  RACThemeProvider,
  StylesProvider,
  createGenerateClassName,
} from '@rentacenter/racstrap';
// import clsx from 'clsx';

import { ErrorBoundary } from '../components/ErrorBoundary/ErrorBoundary';
import { AuthContextProvider } from '../context/auth/AuthContextProvider';
import { AuthGuard } from '../components/AuthGuard/AuthGuard';
//import { DatadogProvider, DatadogProviderConfiguration } from '@datadog/mobile-react-native';

// import '../styles/global.css';
// import { createBrowserHistory } from 'history';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const { createBrowserHistory } = require('history');

export const appTestId = 'appTestId';
const generateClassName = createGenerateClassName({
  //disableGlobal: true,
  //productionPrefix: 'racprodmenu',
  seed: 'RAC-HEADER-',
});

const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});
/*
const datadogConfiguration = new DatadogProviderConfiguration(
  'pub31c376da00d50fcd551c38c2d2bded3a',
  'local',
  'd2c342e2-07ef-4185-9446-bb3ade29c721',
  true,
  true
);

datadogConfiguration.site = 'datadoghq.com';
datadogConfiguration.serviceName = 'racpad-ui';
datadogConfiguration.resourceTracingSamplingRate = 100;
datadogConfiguration.firstPartyHosts = ["rentacenter.com", "menu-racpad.rentacenter.com"];*/

export const App = () => {
  const enableLogs = window.sessionStorage.getItem('enableLogs');
  if (enableLogs != '1') {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    console.log = function () {};
  }
  return (
    <div data-testid={appTestId}>
      <StylesProvider generateClassName={generateClassName}>
        <RACThemeProvider>
          <ErrorBoundary>
            <Router history={history}>
              <AuthContextProvider>
                <AuthGuard />
              </AuthContextProvider>
            </Router>
          </ErrorBoundary>
        </RACThemeProvider>
      </StylesProvider>
    </div>
  );
};
