import { Dispatch } from 'react';

import { Action } from '../Action';
import { getCurrentUser } from '../../api/user';
import {
  getUserAction,
  getUserSuccessAction,
  getUserErrorAction,
} from './user/user-actions';
import { User } from '../../domain/User/User';

export interface GlobalThunksContextValue {
  getCurrentUser(): Promise<void>;
}

export const getCurrentUserThunk = (dispatch: Dispatch<Action>) => async () => {
  try {
    dispatch(getUserAction(true));

    const user: User = await getCurrentUser();

    dispatch(getUserSuccessAction(user));
  } catch (error: any) {
    dispatch(getUserErrorAction(error));
  }
};
