import React, { useEffect, useState, createContext } from 'react';
// import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import clsx from 'clsx';

// import { Aside } from '../../components/layout/aside/Aside';
// import { Header } from '../../components/layout/header/Header';
import { Header } from '../../components/Header/HeaderComponent';
// import { ScrollTo } from '../../components/layout/ScrollTo';
import { useGlobalStateContext } from '../../context/global/global-context';
import { AllowedRoute, getAllowedRoutes } from './routes/getAllowedRoutes';
import { RoutesMapper } from './routes/RoutesMapper';
import { NoUser } from './routes/NoPermissions';
import { StaticContent } from '../../config/static-content-config';
// import Header from '../../components/Header/HeaderComponent';
// import styles from './Authenticated.module.scss';
// import appConfigItem from '../../config/app-config';
import { useFullscreen } from '../../utils/useFullscreen';
import {
  AllowedSubRoute,
  getAllowedSubRoutes,
} from './routes/getAllowedSubRoutes';
import { getmenuandstore } from '../../api/user';

export const authenticatedAppTestId = 'authenticatedAppTestId';
export const UserContext = createContext({});
export const ContainerContext = createContext({});

//eslint-disable-next-line sonarjs/cognitive-complexity
export const Authenticated = withRouter(() => {
  const isFullscreen = useFullscreen();
  const [storeData, setstoreData] = useState();
  const [activeStore, setactiveStore]: any = useState();
  const [role, setRole] = useState<string>();
  // const [helperData, setHelperData]: any = useState({
  //   whatsNewURL: '',
  //   jobAidURL: '',
  //   serviceNowURL: '',
  //   phoneNumber: '-',
  // });
  // const previousPermissions = useRef(props.passingData);
  // const [checkingState, setcheckingState]: any = useState();

  // const hasCollapsedChosen =
  //   sessionStorage.getItem('hasCollapsedChosen') === 'true';

  // const [isCollapsed, setIsCollapsed] = React.useState(
  //   hasCollapsedChosen || false
  // );

  // const handleSidebarCollapsed = () => {
  //   setIsCollapsed(!isCollapsed);
  //   sessionStorage.setItem('hasCollapsedChosen', `${!isCollapsed}`);
  // };

  // useEffect(() => {
  //   const rootDiv = document?.getElementById('root');
  //   if (isCollapsed) {
  //     rootDiv?.setAttribute('collapsed-sidebar', '');
  //   } else {
  //     rootDiv?.removeAttribute('collapsed-sidebar');
  //   }
  // }, [isCollapsed]);
  // useEffect(() => {

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [user]);
  /* eslint-disable no-console */

  const {
    currentUser: { user, error: userError },
  } = useGlobalStateContext();

  const GetRole = () => {
    return role;
  };
  const GetName = () => {
    return user?.firstName + ' ' + user?.lastName;
  };

  const GetEmployeeId = () => {
    return user?.employeeId;
  };

  const GetEmailId = () => {
    return user?.email;
  };

  let allowedRoutes = undefined;
  let allowedMainRoutes = [] as AllowedRoute[];
  let allowedSubRoutes = [] as AllowedSubRoute[];
  if (user) {
    const InventoryVerifyArray = user.permissions?.NAV_MENU
      ? user.permissions?.NAV_MENU.filter((el: any) => el === 'RPDINV')
      : [];
    const ManualPoVerifyArray = user.permissions?.NAV_MENU
      ? user.permissions?.NAV_MENU.filter(
          (el: any) => el === 'RPDMANPO' || el === 'RPDPORECEIVING'
        )
      : [];
    const getAllowedSubRoutesArray: any = Array.from(
      user.permissions?.NAV_MENU || []
    );
    if (InventoryVerifyArray.length == 0 && ManualPoVerifyArray.length > 0) {
      getAllowedSubRoutesArray.push('RPDINV');
    }
    getAllowedSubRoutesArray.push('RPDADMIN');

    console.log(
      'Console getAllowedSubRoutesArray',
      getAllowedSubRoutesArray,
      user.permissions?.NAV_MENU
    );
    console.log('Nav menu', user.permissions?.NAV_MENU);
    allowedMainRoutes = getAllowedRoutes(getAllowedSubRoutesArray);
    allowedSubRoutes = getAllowedSubRoutes(user.permissions?.NAV_MENU);
    allowedRoutes = [...allowedMainRoutes, ...allowedSubRoutes];
    console.log('Allowded routes configured ', allowedSubRoutes);
  }

  useEffect(() => {
    // if (previousPermissions != allowedRoutes) {
    const PageLoad = async () => {
      if (user != undefined) {
        const empId = {
          coworkerId: user.employeeId,
          storesRequired: true,
        };
        const result = await getmenuandstore(empId);
        result.storeAssignment.sort((a: any, b: any) =>
          a.accountingUnitNumber > b.accountingUnitNumber ? 1 : -1
        );
        setactiveStore(result.storeAssignment[0].accountingUnitNumber);
        setstoreData(result.storeAssignment);
        setRole(result.coworkerProfile?.role);

        // const configPayload = {
        //   storeNumbers: [String(window.sessionStorage.getItem('storeNumber'))],
        //   paramKeyNames: [
        //     'ReleaseNotesURL',
        //     'ServiceNowURL',
        //     'JobAidURL',
        //     'SupportContactNumber',
        //   ],
        // };
        // const configResult = await configCall(configPayload);
        // if (configResult) {
        //   const filterReleaseNotesUrl =
        //     configResult.storeProfileResponse.configDetails[0].configDetails.filter(
        //       (el: any) => el.paramKeyName == 'ReleaseNotesURL'
        //     );
        //   const filterSNUrl =
        //     configResult.storeProfileResponse.configDetails[0].configDetails.filter(
        //       (el: any) => el.paramKeyName == 'ServiceNowURL'
        //     );
        //   const filterJobAidUrl =
        //     configResult.storeProfileResponse.configDetails[0].configDetails.filter(
        //       (el: any) => el.paramKeyName == 'JobAidURL'
        //     );
        //   const filterStorePHNumber =
        //     configResult.storeProfileResponse.configDetails[0].configDetails.filter(
        //       (el: any) => el.paramKeyName == 'SupportContactNumber'
        //     );
        //   setHelperData({
        //     whatsNewURL: filterReleaseNotesUrl[0].paramValue,
        //     jobAidURL: filterJobAidUrl[0].paramValue,
        //     serviceNowURL: filterSNUrl[0].paramValue,
        //     phoneNumber: filterStorePHNumber[0].paramValue,
        //   });
        // }
      }
    };
    PageLoad();
  }, [user]);

  return (
    <div data-testid={authenticatedAppTestId}>
      {/* <ScrollTo verticalAxis={0} /> */}
      <UserContext.Provider value={{ activeStore, setactiveStore }}>
        <ContainerContext.Provider
          value={{ GetRole, GetEmployeeId, GetName, GetEmailId }}
        >
          <div className={clsx(!isFullscreen)}>
            {/* {!isFullscreen && (
              <Aside
                allowedRoutes={allowedRoutes}
                handleSidebarCollapsed={handleSidebarCollapsed}
                isCollapsed={isCollapsed}
              />
            )} */}
            <Header
              key={allowedRoutes}
              passingData={allowedMainRoutes}
              allowedSubRoutes={allowedSubRoutes}
              dropDownData={storeData}
              userData={user ? user : {}}
              userRole={role ? role : ''}
              menuNavPermissions={
                user !== undefined &&
                user.permissions !== undefined &&
                user.permissions.NAV_MENU !== undefined
                  ? user.permissions.NAV_MENU
                  : ''
              }
            />
            {activeStore !== undefined &&
            activeStore !== null &&
            activeStore !== '' ? (
              <main className="main">
                {userError ? (
                  <NoUser
                    title={StaticContent.NoPermissionMessage}
                    text={StaticContent.ContactAdministratorMessage}
                  />
                ) : (
                  <RoutesMapper
                    key={allowedRoutes}
                    allowedRoutes={allowedRoutes}
                  />
                )}
              </main>
            ) : null}
          </div>
        </ContainerContext.Provider>
      </UserContext.Provider>
    </div>
  );
});
