import { NavMenuPermission } from './NavMenuPermission';

export const canAccessDash = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_DASH;

export const canAccessPay = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_PAYMENT;

export const canAccessPay1 = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_PAY;

export const canAccessCustomer = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_CUSTOMERS;

export const canAccessAccountManagement = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_ACCTMGMT;

export const canAccessInventory = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_INV;

export const canAccessReports = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_REPORTS;

export const canAccessStoreMagement = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_STRMNGT;

export const canAccessAgreement = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_AGR;

export const canAccessManualPO = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_MANUAL_PO;

export const canAccessConfigurations = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_CONFIGURATIONS;

export const canAccessAdmin = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_ADMIN;

export const canAccessDailyActivityPlanner = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_DAILY_ACTIVITY_PLANNER;

export const canAccessDOF = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_DOF;

export const canAccessDrop = (code: NavMenuPermission) => {
  // eslint-disable-next-line no-console
  console.log(
    'Inside code',
    code === NavMenuPermission.ACCESS_DROP,
    code,
    NavMenuPermission.ACCESS_DROP
  );
  return code === NavMenuPermission.ACCESS_DROP;
};

export const canAccessPricing = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_PRICING;

export const canAccessPOReceiving = (code: NavMenuPermission) =>
  code === NavMenuPermission.ACCESS_PO_RECEIVING;
