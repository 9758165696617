import React from 'react';
//import { Spinner } from 'reactstrap';

import { Routes } from './Routes';
// import { AllowedRoute } from './getAllowedRoutes';
import { NoPermissions } from './NoPermissions';
import { StaticContent } from '../../../config/static-content-config';
// import { RouteComponentProps } from 'react-router';

export const spinnerTestId = 'spinnerTestId';

// interface Props extends RouteComponentProps {
//   allowedRoutes?: AllowedRoute[];
// }

export const RoutesMapper = ({ allowedRoutes }: any) => {
  if (!allowedRoutes) {
    return <div></div>;
  }

  if (allowedRoutes.length > 0) {
    return <Routes key={allowedRoutes} allowedRoutes={allowedRoutes} />;
  }

  return (
    <NoPermissions
      title={StaticContent.NoProductMessage}
      text={StaticContent.ContactSupportMessage}
    />
  );
};
